// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--22w0l";
export var linksNav = "styles-module--linksNav--1okpS";
export var legalNav = "styles-module--legalNav--3lGSq";
export var socialNav = "styles-module--socialNav--3c1jj";
export var sectionTitle = "styles-module--sectionTitle--aX5cm";
export var footerMosaic = "styles-module--footerMosaic--3WLUC";
export var footerLinks = "styles-module--footerLinks--33Lv5";
export var footerLegal = "styles-module--footerLegal--3pW_t";
export var contactLink = "styles-module--contactLink--1a63R";
export var socialLink = "styles-module--socialLink--L3nGn";
export var bounce = "styles-module--bounce--2Cm-a";