import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import MediaQuery from 'react-responsive';
import MenuHandle from '../MenuHandle';
import Nav from '../Nav';
import * as styles from './styles.module.scss';
import CenterLogo from '../../assets/images/TELEMUNDO_CENTER_LOGO_RGB.jpg'

function Header({ setFade }) {
  const [navIsActive, setMenuState] = useState(false);
  const [fadeOnScroll, setFadeOnScroll] = useState(null);

  const handleClick = () => {
    setMenuState(!navIsActive);
  };

  // updating state "fadeOnScroll" when user scrolls past the header height
  // to then use this to add a class to the site header
  useEffect(() => {
    const headerHeight = document.getElementById('site-header').clientHeight;
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > headerHeight) {
        setFadeOnScroll(true);
      } else {
        setFadeOnScroll(null);
      }
    });
  }, []);

  return (
    <header
      id="site-header"
      className={[
        styles.header,
        navIsActive && styles.headerNavActive,
        setFade && styles.fadeStyle,
        setFade && fadeOnScroll && styles.fadeStyleActive,
      ].join(' ')}
    >
      <a href="/">
        <img className={styles.img} src={CenterLogo}/>
      </a>

      <div className={styles.navHandle}>
        <MenuHandle
          onClick={handleClick}
          onKeyDown={handleClick}
          isNavActive={navIsActive}
          role="button"
          tabIndex={0}
        />
      </div>

      <div className={styles.headerNav}>
        <div className={styles.headerNavWrapper}>
          <MediaQuery maxDeviceWidth={999}>
            <Link className="icon-logo" to="/" />
          </MediaQuery>

          <Nav styleOverride={{ navItem: styles.navItem, navLink: styles.navLink }} />
          {/* This is commented out until the Studios Services Site has social links. */}
          {/* <ul className={styles.headerSocial}>
            <li><a className="icon-instagram" href="https://www.instagram.com/nbcutelemundo/" target="_blank" rel="noopener noreferrer"><span>instagram</span></a></li>
            <li><a className="icon-twitter" href="https://twitter.com/nbcutelemundo?lang=en" target="_blank" rel="noopener noreferrer"><span>twitter</span></a></li>
            <li><a className="icon-facebook" href="https://www.facebook.com/NBCUTelemundo/" target="_blank" rel="noopener noreferrer"><span>facebook</span></a></li>
            <li><a className="icon-linkedin" href="https://www.linkedin.com/company/nbcu-telemundo-enterprises/" target="_blank" rel="noopener noreferrer"><span>linkedin</span></a></li>
          </ul> */}
        </div>

        <div className={styles.gradientBar} />
      </div>

      { navIsActive
        && (
          <span
            className={styles.overlay}
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
            aria-label="overlay"
          />
        )}
    </header>
  );
}

Header.propTypes = {
  setFade: PropTypes.bool,
};

Header.defaultProps = {
  setFade: false,
};

export default Header;
