import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../Header';
import Footer from '../Footer';
import * as styles from './styles.module.scss';

// TODO: Temporarily adding/calling the custom icon font file here
// until a better solution is found to where we can place it.
import '../../assets/fonts/telemundo/style.scss';

const Layout = ({ children, headerFade, fullWidth }) => {
  const data = useStaticQuery(graphql`
    query HeaderFooterQuery {
      header: site {
        siteMetadata {
          title
        }
      }

      footer: footerJson {
        legalNav {
          id
          title
          url
        }
      }
    }
  `);
  

  return (
    <>
      <Header
        siteTitle={data.header.siteMetadata.title}
        setFade={headerFade}
      />

      <main
        className={[
          styles.wrapper,
          headerFade && styles.headerFadeActive,
          fullWidth && styles.fullWidth,
        ].join(' ')}
      >
        {children}
      </main>

      <Footer
        navData={data.footer.nav}
        mosaicData={data.footer.ourContent}
        legalNav={data.footer.legalNav}
        socialData={data.footer.socialNav}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerFade: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Layout.defaultProps = {
  headerFade: null,
  fullWidth: null,
};

export default Layout;
