import React from 'react';
import PropTypes from 'prop-types';
import LinkElement from '../LinkElement';
import * as styles from './styles.module.scss';

const Footer = ({
  className,
  navData,
  legalNav,
  socialData,
}) => {
  let date = new Date().getFullYear();
  let copyright_date = "TELEMUNDO " + date;

  return (
    <footer className={[styles.footer, className].join(' ')}>
      <div className={styles.footerLegal}>
        <nav className={styles.legalNav}>
          <ul>
            <li className={styles.copyright}>&copy; {copyright_date}</li>
            {legalNav.map((link) => (
              <li key={link.id} className={styles[link.class]}>
                <a
                  href={link.url}
                  aria-label={link.title}
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              </li>
            ))}
          </ul>
        </nav>

        {/* <nav className={styles.socialNav}>
          <ul>
            {socialData.map((socialLink) => (
              <li key={socialLink.id}>
                <a
                  className={[styles.socialLink, socialLink.class].join(' ')}
                  href={socialLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{socialLink.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav> */}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  navData: PropTypes.arrayOf(PropTypes.object),
  legalNav: PropTypes.arrayOf(PropTypes.object),
  socialData: PropTypes.arrayOf(PropTypes.object),
};

Footer.defaultProps = {
  className: null,
  navData: [],
  legalNav: [],
  socialData: [],
};

export default Footer;
