// extracted by mini-css-extract-plugin
export var gradientBar = "styles-module--gradientBar--2XALk";
export var header = "styles-module--header--1nv0f";
export var img = "styles-module--img--1Nv9U";
export var headerLogo = "styles-module--headerLogo--1BY6k";
export var navHandle = "styles-module--navHandle--2E6w3";
export var headerNav = "styles-module--headerNav--2PqKF";
export var headerNavActive = "styles-module--headerNavActive--1xLz5";
export var headerNavWrapper = "styles-module--headerNavWrapper--3HKec";
export var navLink = "styles-module--navLink--1m4Dl";
export var headerSocial = "styles-module--headerSocial--zxmbI";
export var overlay = "styles-module--overlay--xSW_3";
export var fadeStyle = "styles-module--fadeStyle--2FOp-";
export var navItem = "styles-module--navItem--2e1ck";
export var fadeStyleActive = "styles-module--fadeStyleActive--3tk_x";
export var bounce = "styles-module--bounce--15W-x";