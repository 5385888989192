import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.module.scss';

const MenuHandle = ({ isNavActive, onClick }) => (
  <div
    className={[styles.menuHandle, isNavActive && styles.navIsActive].join(' ')}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={0}
  >
    <span />
    <span />
    <span />
    <span />
  </div>
);

MenuHandle.propTypes = {
  onClick: PropTypes.func,
  isNavActive: PropTypes.bool,
};

MenuHandle.defaultProps = {
  onClick: null,
  isNavActive: null,
};

export default MenuHandle;
