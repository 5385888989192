import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import * as styles from './styles.module.scss';
import NavData from '../../data/nav.json';
import LinkElement from '../LinkElement';

const Nav = ({ styleOverride }) => {
  const [subMenuState, setSubMenuState] = useState({});

  const toggleSubMenu = (id) => {
    setSubMenuState((prevSubMenuState) => ({
      ...prevSubMenuState, [id]: !prevSubMenuState[id],
    }));
  };

  return (
    <nav className={styles.mainNav}>
      <ul>
        {NavData.map((link) => (
          <li key={link.id} className={[styles.navItem, styleOverride.navItem, subMenuState[link.id] && styles.expanded].join(' ')}>
            {link.url
              ? (
                <LinkElement
                  className={[styles.navLink, styleOverride.navLink].join(' ')}
                  to={link.url}
                  text={link.title}
                />
              ) : link.title}

            {/* show submenu if submenu array is not empty */}
            {link.submenu && link.submenu.length
              ? (
                <>
                  <MediaQuery maxDeviceWidth={999}>
                    <button type="button" className={styles.subMenuBtn} onClick={() => toggleSubMenu(link.id)}>
                      {subMenuState[link.id] ? String.fromCharCode(8722) : '+'}
                    </button>
                  </MediaQuery>

                  <ul className={[styles.subMenu, subMenuState[link.id] && styles.expand].join(' ')}>
                    {link.submenu.map((sublink) => (
                      <li key={sublink.id} className={styles.subMenuItem}>
                        {/* passing state of sublink id to press page */}
                        <LinkElement
                          to={sublink.url}
                          state={{ title: sublink.id }}
                          text={sublink.title}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
          </li>
        ))}
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  styleOverride: PropTypes.shape({
    navItem: PropTypes.string,
    navLink: PropTypes.string,
  }),
};

Nav.defaultProps = {
  styleOverride: {
    navItem: '',
    navLink: '',
  },
};

export default Nav;
