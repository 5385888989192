import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const LinkElement = ({
  to,
  state,
  text,
  className,
}) => {
  function isURLExternal(urlProp) {
    return (/^https?:\/\//.test(urlProp));
  }

  return (
    isURLExternal(to)
      ? <a href={to} className={className} target="_blank" rel="noopener noreferrer">{text}</a>
      : <Link className={className} to={to} state={state}>{text}</Link>
  );
};

LinkElement.propTypes = {
  to: PropTypes.string,
  state: PropTypes.shape({}),
  text: PropTypes.oneOfType([() => null, PropTypes.string]),
  className: PropTypes.string,
};

LinkElement.defaultProps = {
  to: null,
  state: {},
  text: '',
  className: null,
};

export default LinkElement;
